<template>
    <HeaderSection v-bind="headerProps" />
    <section class="hero-media">
		<Carousel :items="mediaInfo" />
	</section>

    <div class="content-wrap">
		<div class="entry entry-page">
			<h2 class="entry-title">{{content.title}}</h2>
			<div class="entry-content">
				<SignContract v-if="contract?.id" :products="products" :onSave="onSave" :item="contract" :event="event" :userId="userId" :cart="cart" />
                <!-- <Button type="submit" @click="submit" class="button-filled button-color submit-btn" size="size" variant="abc">Complete Booking</Button> -->
			</div>
		</div>
		<CopyRight v-bind="footerProps" class="-mt-2 mb-20"/>
	</div>
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import moment from 'moment';
import Carousel from '../../organisms/Carousel/Carousel.vue';
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps } from '../../constants';
import Text from '../../atoms/Text/Text.vue'
import SignContract from '../../organisms/SignContract/SignContract.vue';
/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        HeaderSection, Carousel, CopyRight, Text, SignContract
    },
    props: {
        /**
         * Use it for notification title
         */
        headerProps: {
            type: Object,
            default: () => null
        },
        mediaInfo: {
            type: Object,
            default: () => null
        },
        content: {
            type: Object,
            default: () => null
        },
        event: {
            type: Object,
            default: () => {}
        },
        contract: {
            type: Object,
            default: () => {}
        },
        onSave: {
            type: Function,
            default: () => {}
        },
        userId: {
            type: String,
            default: ''
        },
        cart: {
            type: Object,
            default: () => {}
        },
        products: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            footerProps,
            moment: moment,
        }
    },
}
</script>

<style>

</style>