<template>
  <div class="flex flex-col flex-1">
    <div class="flex overflow-hidden w-full divide-x flex-1">
      <div
        class="flex flex-1 overflow-scroll pb-10 pt-10 justify-center"
        id="document"
      >
        <div
          :style="{
            width: `${containerWidth * scale}px`,
            height: `${containerHeight * scale}px`,
          }"
        >
          <div
            v-for="(image, i) in images"
            :key="i"
            :style="{
              paddingLeft: `${20 * scale}px`,
              paddingRight: `${20 * scale}px`,
            }"
          >
            <div
              @mouseenter="() => (image.isOver = true)"
              @mousemove="(e) => mouseMove(e, i)"
              @mouseleave="() => (image.isOver = false)"
              :id="`page_${i}`"
              @click="() => onClickItem(image)"
              :style="{
                width: `${imgWidth * scale}px`,
                height: `${imgHeight * scale}px`,
              }"
              class="relative"
            >
              <img :src="image.src" class="w-full h-full" />
              <div
                class="bg-yellow-500 opacity-70 absolute px-2 py-1 text-white"
                id="create-element"
                v-if="
                  image.isOver &&
                  selectedItem?.name &&
                  currentX > 0 &&
                  currentY > 0
                "
                :style="{ left: `${currentX}px`, top: `${currentY}px` }"
                style="font-size: 0.7vw"
              >
                {{ selectedItem?.name }}
              </div>
              <div
                v-for="(element, j) in image.elements"
                :key="j"
                v-click-outside="() => (element.active = false)"
              >
                <a
                  v-if="element?.name?.includes('Signature')"
                  @click="
                    () => {
                      selectedElement = element;
                      isOpen = true;
                    }
                  "
                  class="
                    flex
                    justify-center
                    items-center
                    absolute
                    text-white text-xs
                    cursor-pointer
                  "
                  :class="
                    element.signSrc ? 'bg-white' : 'bg-indigo-500 opacity-70'
                  "
                  :style="{
                    left: `${element.x}px`,
                    top: `${element.y}px`,
                    width: `${element.width}px`,
                    height: `${element.height}px`,
                  }"
                >
                  <img
                    v-if="element.signSrc"
                    :src="element.signSrc"
                    class="w-full h-full"
                  />
                  <div v-else style="font-size: 10px">{{ element?.name }}</div>
                </a>
                <div
                  v-else-if="element?.name === 'Products List'"
                  class="absolute"
                  :style="{ left: `${element.x}px`, top: `${element.y}px` }"
                >
                  <ul>
                    <li
                      v-for="item in products?.filter(
                        (p) =>
                          event?.metadata?.equipments?.find(
                            (e) => e === p?.id
                          ) ||
                          event?.metadata?.services?.find((s) => s === p?.id)
                      )"
                      :key="item?.id"
                    >
                      {{
                        `${item?.name} x 1 = $${
                          item?.pricing?.[0]?.retailPrice || 0
                        }`
                      }}
                    </li>
                  </ul>
                </div>
                <div
                  v-else
                  class="absolute text-black text-sm"
                  :style="{ left: `${element.x}px`, top: `${element.y}px` }"
                >
                  {{ getValue(element) }}
                </div>
              </div>
            </div>
            <div
              class="flex justify-between"
              :style="{
                height: `${30 * scale}px`,
                paddingTop: `${5 * scale}px`,
              }"
            >
              <Text
                size="sm"
                weight="medium"
                :content="contract?.name"
                color="gray-600"
              />
              <Text
                size="sm"
                weight="medium"
                :content="`${i + 1} of ${images.length}`"
                color="gray-600"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <SignatureModal
      @onClose="() => (isOpen = false)"
      :element="selectedElement"
      :open="isOpen"
    />
    <div
      class="
        fixed
        bottom-0
        right-16
        flex
        justify-end
        items-end
        max-w-5xl
        w-full
        mx-auto
        sm:px-6
        sm:-ml-6
        lg:-ml-8
        lg:px-8
      "
    >
      <div class="flex items-end justify-end px-2 py-2 w-full bg-white border">
        <Button content="Download" customClass="bg-black" @click="onDownload" />
        <Button
          content="Save"
          :isLoading="isLoading"
          customClass="bg-black"
          @click="onSave"
        />
      </div>
    </div>
    <div id="render"></div>
  </div>
</template>
<script>
import Text from "../../atoms/Text/Text.vue";
import Button from "../../atoms/Button/Button.vue";
import Icon from "../../atoms/Icons/Icons.vue";
import Select from "../../molecules/Selects/Component/Component.vue";
import vClickOutside from "click-outside-vue3";
import $ from "jquery";
import SignatureModal from "../../organisms/Modals/SignatureModal/SignatureModal";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import AWS from "aws-sdk";
import storage from "../../../store/effects/storage";
import moment from "moment";

const GAP_X = 20;
const GAP_Y = 30;
export default {
  components: {
    Text,
    Button,
    Icon,
    Select,
    SignatureModal,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      images: [],
      imgHeight: null,
      imgWidth: null,
      containerWidth: null,
      containerHeight: null,
      scale: null,
      selectedItem: null,
      currentX: null,
      currentY: null,
      selectedElement: null,
      isSaving: false,
      contract: null,
      isOpen: false,
      isLoading: false,
    };
  },
  watch: {
    images: {
      handler(val) {
        const image = this.images.find((i) =>
          i.elements?.find((e) => e.name === "Signature 1" && e.signSrc)
        );
        if (image) {
          const signSrc = image.elements.find(
            (e) => e.name === "Signature 1" && e.signSrc
          )?.signSrc;
          this.images.map(
            (i) =>
              (i.elements.find((e) => e.name === "Signature 1").signSrc =
                signSrc)
          );
        }
      },
      deep: true,
    },
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    event: {
      type: Object,
      default: () => {},
    },
    onSave: {
      type: Function,
      default: () => {},
    },
    userId: {
      type: String,
      default: "",
    },
    cart: {
      type: Object,
      default: () => {},
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async onDownload() {
      const doc = new jsPDF();
      for (let i = 0; i < this.images.length; i++) {
        const image = (
          await html2canvas(document.getElementById(`page_${i}`))
        ).toDataURL();
        console.log(image, "image");
        const newImage = document.createElement("img");
        // newImage.style.display = 'none';
        newImage.src = image;
        // document.appendChild(image)
        doc.addImage(newImage, 1, 1);
        if (i < this.images.length - 1) {
          doc.addPage();
        }
      }
      doc.save("signature.pdf");
    },
    async getAWSCredential(actions) {
      const credential = await storage.getData("aws_credential");
      if (new Date(credential?.s3?.Credentials.Expiration) > new Date()) {
        return { s3: credential?.s3, mediaConvert: credential?.mediaConvert };
      } else {
        const { awsTempToken: s3Token } = await actions.user.awsTempToken({
          service: "s3",
        });
        const { awsTempToken } = await actions.user.awsTempToken({
          service: "mediaConvert",
        });
        await storage.saveData(
          { s3: s3Token, mediaConvert: awsTempToken },
          "aws_credential"
        );
        return { s3: s3Token, mediaConvert: awsTempToken };
      }
    },
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: contentType });
    },
    async onSave() {
      if (this.isLoading) return false;
      this.isLoading = true;
      const prefix = new Date().getTime();
      const images = [];
      const { s3 } = await this.getAWSCredential(this.actions);
      const s3Bucket = new AWS.S3({
        params: { Bucket: s3.bucket },
        apiVersion: "2006-03-01",
        region: s3.region,
        credentials: {
          accessKeyId: s3.Credentials.AccessKeyId,
          secretAccessKey: s3.Credentials.SecretAccessKey,
          sessionToken: s3.Credentials.SessionToken,
        },
      });
      for (let i = 0; i < this.images.length; i++) {
        const image = (
          await html2canvas(document.getElementById(`page_${i}`), {})
        ).toDataURL();
        console.log(image, "image");
        // const newImage = document.createElement('img')
        // // newImage.style.display = 'none';
        // newImage.src = image;
        // // document.appendChild(image)
        // document.getElementById('render').appendChild(image)

        const buffer = this.b64toBlob(
          image.replace(/^data:image\/\w+;base64,/, ""),
          "image/png"
        );
        const filename = `_CONTRACTS/${this.userId}/${
          this.contract?.id
        }/sign_${prefix}_${i + 1}.png`;
        let contentType = "image/png";
        let contentDeposition = 'inline;filename="' + filename + '"';
        const params = {
          Bucket: s3.bucket,
          Key: filename,
          Body: buffer,
          ContentDisposition: contentDeposition,
          ContentType: contentType,
          ACL: "public-read",
        };
        const res = await s3Bucket.upload(params).promise();
        console.log("Response URL : " + res.Location);
        images.push({ name: filename, source: res.Location });
      }

      // this.$emit('onSave', images)
      await this.onSave(images);
      this.isLoading = false;
    },
    getValue(element) {
      switch (element.name) {
        case "Id":
          return this.event?.id?.slice(-6);
        case "Client Name":
          return (
            this.event?.creator?.firstName + " " + this.event?.creator?.lastName
          );
        case "Client Phone":
          return this.event?.creator?.phones?.[0]?.number;
        case "Client Email":
          return this.event?.creator?.email;
        case "Type of Event":
          return this.event?.metadata?.type;
        case "Setup Date/Time":
          return moment(this.event?.days?.[0]?.startDate)
            .subtract(this.event?.metadata?.setup_time, "hours")
            .format("MMMM DD, YYYY, h:mm A");
        case "Event Start Time":
          return moment(this.event?.days?.[0]?.startDate).format(
            "MMMM DD, YYYY, h:mm A"
          );
        case "Event End Time":
          return moment(this.event?.days?.[0]?.endDate).format(
            "MMMM DD, YYYY, h:mm A"
          );
        case "Current Date":
          return moment().format("MMMM DD, YYYY");
        case "Date Signed":
          return moment().format("MMMM DD, YYYY");
        case "Clean-up Ent Time":
          return (
            moment(this.event?.days?.[0]?.endDate).format("MMMM DD, YYYY") +
            ", 11:00 AM"
          );
        case "Cart Items":
          return this.cart?.items?.[0]?.priceEach;
        case "Cart Total":
          return this.cart?.total;
        case "Security Deposit":
          return this.cart?.securityDeposit || 500;
        case "Deposit":
          return this.cart?.deposit || 1000;
        case "Total Deposit":
          return (
            (this.cart?.securityDeposit || 500) + (this.cart?.deposit || 1000)
          );
        case "Cart Balance":
          return this.cart?.balance || this.cart?.total - 1000;
        default:
          return "";
      }
    },
    getImageSize() {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const self = this;
        img.onload = function () {
          console.log(this.width, this.height);
          self.imgWidth = this.width;
          self.imgHeight = this.height;
          resolve(true);
        };
        img.src = this.images[0]?.src;
      });
      // let img = document.createElement('img');
      // img.style.visibility = 'hidden';
      // img.id = 'imgId';
      // img.src = this.images[0].src;
      // document.body.appendChild(img);
      // let myImg = document.querySelector("#imgId");
      // for (let i = 0; i < 10000; i++) {
      //     this.imgWidth = myImg.naturalWidth;
      //     this.imgHeight = myImg.naturalHeight;
      //     if (this.imgWidth > 0 && this.imgHeight > 0) {
      //         break;
      //     }
      //     i++
      // }
    },
    onInitialize() {
      const width = document.getElementById("document").offsetWidth;
      this.containerWidth = this.imgWidth + GAP_X * 2;
      this.containerHeight = (this.imgHeight + GAP_Y) * this.images.length;
      this.scale = width / this.containerWidth;
      if (this.scale > 2) this.scale = 2;
      this.resizeElements();
    },
    mouseMove(e, i) {
      this.currentX =
        e.pageX - $(window).scrollLeft() - $(`#page_${i}`).offset().left;
      this.currentY =
        e.pageY - $(window).scrollTop() - $(`#page_${i}`).offset().top;
    },
    onClickItem(image) {
      const element = document.getElementById("create-element");
      if (image.isOver && this.selectedItem?.name) {
        image.elements.push({
          name: this.selectedItem?.name,
          x: this.currentX,
          y: this.currentY,
          width: element.offsetWidth,
          height: element.offsetHeight,
          origX: this.currentX / this.scale,
          origY: this.currentY / this.scale,
          origWidth: element.offsetWidth / this.scale,
          origHeight: element.offsetHeight / this.scale,
        });
        image.isOver = false;
        this.selectedItem = null;
      }
    },
    resizeElements() {
      const images = this.images;
      images.map((image) => {
        image.elements?.map((e) => {
          e.x = e?.origX * this.scale;
          e.y = e?.origY * this.scale;
          e.width = e?.origWidth * this.scale;
          e.height = e?.origHeight * this.scale;
        });
      });
      this.images = images;
    },
  },
  async mounted() {
    this.contract = this.item;
    this.contract?.files
      ?.filter((f) => f?.tags?.length === 0)
      ?.map((f) => {
        this.images.push({
          src: f.source,
          elements: f.metadata.elements,
        });
      });
    await this.getImageSize();
    this.onInitialize();
    window.addEventListener("resize", () => {
      this.onInitialize();
    });
  },
};
</script>
<style scoped></style>
