<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto h-full" @close="() => { open = false; $emit('onClose') }">
      <div class="flex items-end justify-center text-center">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
          leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div
            class="fixed top-0 sm:top-8 inline-block sm:rounded-md h-80 align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-4xl sm:w-full ">
            <div class="fixed w-auto top-4 right-3 z-10">
              <div class="hidden sm:flex justify-end w-full">
                <Icon name="XIcon" size="6" isOutline color="gray-600" @click="() => { open = false; $emit('onClose') }"
                  :onClick="() => $emit('onClose')" />
              </div>
            </div>
            <div class="flex flex-col h-full overflow-y-auto sm:p-6 px-4">
              <div class="flex items-center mt-2 justify-between">
                <div class="flex">
                  <Text weight="semibold" color="gray-900" size="xl" content="Signature"/>
                </div>
                <div class="flex h-full sm:hidden mt-2 ml-2 justify-start">
                  <Icon name="XIcon" size="8" isOutline color="gray-600" @click="open = false"
                    :onClick="() => { open = false; $emit('onClose') }" />
                </div>
              </div>
              <div id="sign-container" class="mt-3 h-40 w-auto text-left border w-full">
                <canvas id="signature-pad" width=400px height=400px></canvas>
              </div>
            </div>
            <div class="fixed w-full bottom-0 bg-gray-100 h-auto z-20">
              <div class="flex flex-row items-end justify-end my-4 mr-4 sm:mr-3 lg:mr-6">
                <Button @click="() => { onClear() }" content="Clear" variant="secondary" customClass="ml-4 px-4" />
                <Button @click="() => { onSave(); open = false; }" content="Save" :variant="null"
                  customClass="ml-4 px-4 bg-black" />
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import Icon from '../../../atoms/Icons/Icons.vue'
import Button from '../../../atoms/Button/Button.vue'
import Text from '../../../atoms/Text/Text.vue'
import Vue3Signature from "vue3-signature"
import SignaturePad from 'signature_pad'

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Icon, Button, Text,
    Vue3Signature
  },
  data() {
    return {
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)"
      },
      signaturePad: null,
    }
  },
  watch: {
    open: function (val) {
      if (val) {
        setTimeout(() => {
          this.signaturePad = new SignaturePad(document.getElementById('signature-pad'), this.option);
          document.getElementById('signature-pad').width = document.getElementById('sign-container').offsetWidth
          document.getElementById('signature-pad').height = 160
        }, 100)
      } else {
        this.$emit('onClose')
      }
    }
  },
  methods: {
    onSave() {
      // console.log(this.$refs.signature.save('image/jpg'));
      this.element.signSrc = this.signaturePad.toDataURL('image/png');
      this.$emit('onClose')
    },
    onClear() {
      this.signaturePad.clear();
    }
  },
  props: {
    /**
     * Use it for yes button text
     */
    open: {
      type: Boolean,
      default: true
    },
    element: {
      type: Object,
      default: () => { }
    }
  },
  mounted() {


  }
}
</script>
