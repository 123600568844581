<template>
  <SignatureTemplate
    :headerProps="headerProps"
    :mediaInfo="mediaInfo"
    :content="content"
    :event="event"
    :cart="cart"
    :onSave="onSave"
    :contract="contract"
    :userId="$route.query?.uId"
    :products="state.product.products"
  />
</template>

<script>
import SignatureTemplate from "@/components/templates/Signature/Signature";
import { headerProps } from "@/components/constants";
import heroImg from "@/assets/images/sample-hero.jpg";

export default {
  components: {
    SignatureTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      mediaInfo: [
        {
          image: heroImg,
        },
      ],
      content: {
        title: "Rental Agreement",
      },
      contract: null,
      event: null,
      cart: null,
    };
  },
  async created() {
    const contracts = await this.actions.contract.getContracts({
      first: 1,
      skip: 4,
      getValues: true,
    });
    this.contract = contracts[0];
    const events = await this.actions.event.getEvents({
      where: { id: this.$route.query?.eId },
      getValues: true,
    });
    this.event = events[0];
    const carts = await this.actions.cart.getCarts({
      where: { id: this.$route.query?.cId },
      getValues: true,
    });
    this.cart = carts[0];
    console.log(this.state.product.products);
    if (this.state.product.products.length === 0) {
      await this.actions.product.getProducts();
    }
    console.log(this.cart, "cart");
  },
  methods: {
    async onSave(images) {
      const tags = await this.actions.tag.getTags({
        where: { name: "SIGNED_CONTRACT" },
        getValues: true,
      });
      let tag = tags[0];
      if (tags?.length === 0) {
        const { saveTag } = await this.actions.tag.saveTag({
          data: {
            name: "SIGNED_CONTRACT",
            type: "TAG",
          },
        });
        tag = saveTag;
      }
      const media = [];
      images.map((image) => {
        media.push({
          type: "IMAGE",
          ...image,
          tags: {
            connect: [{ id: tag?.id }],
          },
        });
      });
      await this.actions.contract.saveContract({
        where: { id: this.contract?.id },
        data: {
          files: {
            create: media,
          },
        },
      });
      this.$router.push({
        name: "Payment",
        query: { cId: this.$route.query?.cId, rId: this.$route.query?.rId },
      });
    },
  },
};
</script>

<style scoped></style>
